import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'bsc',
}

const getTokenLogoURL = (token?: Token) => {
  // console.log("TOKENNNNN")
  if (token && mapping[token.chainId]) {
    // return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${token.address}/logo.png`
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${token.address}/logo.png`
  }
  return null
}

export default getTokenLogoURL
